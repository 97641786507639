<script setup lang="ts">
const appName = getAppName()

useHead({
  titleTemplate(title) {
    return title ? `${title} - ${appName}` : appName
  },
})
</script>

<template>
  <NuxtErrorBoundary @error="showError($event as any)">
    <div class="h-full flex flex-col">
      <MobileNavbar class="lg:hidden" />
      <GlobalBanner />
      <GlobalModals />
      <div class="flex-1 flex flex-col">
        <div class="relative">
          <NavBar />
          <Container class="relative lg:min-h-[250px] pb-16 pt-10 lg:py-20">
            <div v-show="$slots['back-link']" class="lg:absolute lg:top-8">
              <slot name="back-link" />
            </div>
            <slot name="header" />
          </Container>
          <Background />
        </div>
        <div class="-mt-10">
          <div class="w-full pb-4">
            <Container>
              <slot name="content" />
            </Container>
          </div>
        </div>
        <CopyrightFooter variant="light" class="mt-auto" />
      </div>
    </div>
  </NuxtErrorBoundary>
</template>
